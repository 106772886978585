.styled-scrollbars {
  /* Foreground, Background */
  scrollbar-color: #94a3b8 #475569;
}
.styled-scrollbars::-webkit-scrollbar {
  width: 5px; /* Mostly for vertical scrollbars */
  height: 5px; /* Mostly for horizontal scrollbars */
}
.styled-scrollbars::-webkit-scrollbar-thumb {
  /* Foreground */
  border-radius: 10px;
  background: #475569;
}
.styled-scrollbars::-webkit-scrollbar-track {
  /* Background */
  background: white;
}

.tableFixHead {
  overflow: auto;
  height: 100%;
  background: #f8fafc;
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.tableModal {
  overflow: auto;
  background: #f8fafc;
}
.tableModal thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Just common table stuff. Really. */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 16px;
}
th {
  background: #00b1ff;
}

/* drop down options button */
.dropbtn {
  background-color: #00b1ff;
  color: white;
  width: 2rem;
  border: none;
  border-radius: 3px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  z-index: 1;
  display: none;
  position: absolute;
  border-radius: 3px;
  /* transform: translate(-2rem); */
  right: 0;
  top: 0;
  background-color: #e2e8f0;
  width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content button {
  color: black;
  padding: 5px;
  text-decoration: none;
  display: block;
  padding: 8px;
}

.dropdown-content button:hover {
  background-color: #f8fafc;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #7dd3fc;
}

/* multiple choice */
.multiplebtn {
  background-color: #00b1ff;
  color: white;
  width: 2rem;
  border: none;
  border-radius: 3px;
}

.multiple {
  position: relative;
  display: inline-block;
}

.multiple-content {
  display: none;
  position: absolute;
  border-radius: 3px;
  /* transform: translate(-2rem); */
  right: 0;
  bottom: 0;
  background-color: #e2e8f0;
  width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.multiple-content button {
  color: black;
  padding: 5px;
  text-decoration: none;
  display: block;
  padding: 8px;
}

.multiple-content button:hover {
  background-color: #f8fafc;
}

.multiple:hover .multiple-content {
  display: block;
}

.multiple:hover .dropbtn {
  background-color: #7dd3fc;
}

.show {
  display: block;
}
/* onClick button showing */
.dropbtn2 {
  background-color: #00b1ff;
  color: white;
  width: 3rem;
  height: 1.5rem;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: large;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropbtn2:hover,
.dropbtn2:focus {
  background-color: #7dd3fc;
}

.dropdown2 {
  position: relative;
  display: inline-block;
}

.dropdown-content2 button {
  color: black;
  padding: 5px;
  text-decoration: none;
  display: block;
  padding: 8px;
}

.dropdown-content2 {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  right: 0;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content2 button:hover {
  background-color: #f8fafc;
}

.dropdown2 a:hover {
  background-color: #ddd;
}

.show2 {
  display: block;
}
