@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Be Vietnam Pro", sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus,
select:focus,
textarea:focus {
  outline-width: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

html {
  overflow: hidden;
  height: 100%;
}

.css-wsp0cs-MultiValueGeneric {
  white-space: normal !important;
}

@media (max-width: 1024) {
  .header-user-name {
    display: none;
  }

  .header-user-logout {
    position: absolute;
    top: 35px;
    right: 0;
    width: max-content;
  }

  html {
    overflow-y: auto;
    height: max-content;
  }
}